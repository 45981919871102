import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "@firebase/auth";
import axios from "axios";
import { initializeApp } from "firebase/app";
import environment from "../config/environment";

const { firebase } = environment;

const firebaseConfig = {
  apiKey: firebase.API_KEY,
  authDomain: firebase.AUTH_DOMAIN,
  projectId: firebase.projectId,
  storageBucket: firebase.STORAGE_BUCKET,
  messagingSenderId: firebase.MESSAGING_SENDER_ID,
  appId: firebase.APP_ID,
};

initializeApp(firebaseConfig);

const auth = getAuth();

const login = async (email, password) => {
  const result = await signInWithEmailAndPassword(auth, email, password);
  return result;
};

const onAuthChange = (setCurrentUser, setIsLoading) => {
  return onAuthStateChanged(auth, async (user) => {
    setIsLoading(true);
    if (user) {
      await user.getIdToken(true);
      console.log(await user.getIdToken());
      const idTokenResult = await user.getIdTokenResult();
      const claims = idTokenResult.claims;
      let role = null;
      if (claims.store) {
        role = "store";
      } else if (claims.customer) {
        role = "customer";
      } else if (claims.admin) {
        role = "admin";
      }
      setCurrentUser({ user, role });
    } else {
      setCurrentUser(null);
    }
    setIsLoading(false);
  });
};

const logOut = () => {
  return signOut(auth);
};

const signUp = async (store) => {
  const response = await axios.post("/store", store);
  return response;
};

export { onAuthChange, login, logOut, signUp };
