import React from "react";
import { Redirect, Route } from "react-router";
import { useAuthContext } from "../contexts/AuthContext";
import DashboardLayout from "../layouts/DashboardLayout/DashboardLayout";

const AuthRoute = ({ menu, component: Component, ...props }) => {
  const { currentUser } = useAuthContext();
  const user = currentUser?.user;
  const role = currentUser?.role;
  return (
    <Route
      {...props}
      render={(props) => {
        return user && role ? (
          <DashboardLayout menu={menu}>
            <Component {...props} />
          </DashboardLayout>
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

export default AuthRoute;
