import Account from "../pages/Account/Account";
import Drones from "../pages/Drones/Drones";
import Items from "../pages/Items/Items";
import Orders from "../pages/Orders/Orders";
import Pilots from "../pages/Pilots/Pilots";

const storeRoutes = [
  { path: "/store/items", component: Items },
  { path: "/store/drones", component: Drones },
  { path: "/store/pilots", component: Pilots },
  { path: "/store/orders", component: Orders },
  { path: "/store/account", component: Account },
];

export default storeRoutes;
