import useSWR, { useSWRConfig } from "swr";
import axios from "axios";

const path = "/drone";

const useDrones = () => {
  const { data: drones, error } = useSWR(path, axios.get);
  const { mutate } = useSWRConfig();

  const addDrone = async (drone) => {
    const addedDrone = await axios.post(path, drone);
    mutate(path);
    return addedDrone;
  };

  const updateDrone = async (drone) => {
    const updatedDrone = await axios.put(`${path}/${drone.id}`, drone);
    mutate(path);
    return updatedDrone;
  };

  return {
    drones,
    isLoading: !error && !drones,
    error: error,
    addDrone,
    updateDrone,
  };
};

export default useDrones;
