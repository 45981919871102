import React from "react";
import { Modal, Form, Input } from "antd";
import {
  phoneNumberValidator,
  wholeNumberGreaterThanZeroValidator,
} from "../../../../common/validators/validators";

const AddPilotModal = ({ isVisible, onAddPilot, onCancel, isAddingPilot }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={isVisible}
      title="Add Pilot"
      okText="Add"
      cancelText="Cancel"
      onCancel={onCancel}
      confirmLoading={isAddingPilot}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            const {
              firstName,
              lastName,
              phoneNumber,
              account,
              taxId,
              licenseId,
              experience,
            } = values;
            onAddPilot({
              firstName,
              lastName,
              phoneNumber,
              account,
              taxId,
              licenseId,
              experience,
            });
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[
            {
              required: true,
              message: "First name is required!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[
            {
              required: true,
              message: "Last name is required!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="account"
          label="Account"
          rules={[
            {
              required: true,
              message: "Account is required!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="taxId"
          label="Tax ID"
          rules={[
            {
              required: true,
              message: "Tax ID is required!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="licenseId"
          label="License ID"
          rules={[
            {
              required: true,
              message: "License ID is required!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          label="Phone Number"
          rules={[
            {
              required: true,
              message: "Phone number is required!",
            },
            phoneNumberValidator(
              "Phone number has to be the format XXX-XXX-XXXX"
            ),
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="experience"
          label="Experience"
          rules={[
            {
              required: true,
              message: "Experience is required!",
            },
            wholeNumberGreaterThanZeroValidator(
              "Experience needs to be a whole number and greater than 0"
            ),
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddPilotModal;
