import React from "react";
import { Typography, Space } from "antd";

const { Text } = Typography;

const Descriptions = ({ children }) => {
  return <Space direction="vertical">{children}</Space>;
};

const Item = ({ label, children }) => {
  return (
    <>
      <div>
        <Text strong>{label}</Text>
      </div>
      <div>
        <Text>{children ? children : "-"}</Text>
      </div>
    </>
  );
};

Descriptions.Item = Item;

export default Descriptions;
