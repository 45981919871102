import useSWR, { useSWRConfig } from "swr";
import axios from "axios";

const path = "/pilot";

const usePilots = () => {
  const { data: pilots, error } = useSWR(path, axios.get);
  const { mutate } = useSWRConfig();

  const addPilot = async (pilot) => {
    const addedPilot = await axios.post(path, pilot);
    mutate(path);
    return addedPilot;
  };

  return {
    pilots,
    isLoading: !error && !pilots,
    error: error,
    addPilot,
  };
};

export default usePilots;
