import useSWR from "swr";
import axios from "axios";

const path = "/admin/customer";

const useAdminCustomers = () => {
  const { data: customers, error } = useSWR(path, axios.get);

  return {
    customers,
    isLoading: !error && !customers,
    error: error,
  };
};

export default useAdminCustomers;
