import React, { useEffect, useState } from "react";
import { PageHeader, Table } from "antd";
import Container from "../../../../common/components/Container/Container";
import { useMenu } from "../../../../common/contexts/MenuContext";

import useAdminStores from "../../hooks/useAdminStores";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Revenue",
    dataIndex: "revenue",
  },
];

const Stores = () => {
  const { setSelectedMenu } = useMenu();
  const { stores, isLoading } = useAdminStores();
  useEffect(() => {
    setSelectedMenu(["1"]);
  }, []);

  return (
    <>
      <Container noPadding>
        <PageHeader title="Stores" className="site-page-header" />
      </Container>
      <Container>
        <Table
          rowKey={(item) => item.id}
          loading={isLoading}
          columns={columns}
          dataSource={stores}
        />
      </Container>
    </>
  );
};

export default Stores;
