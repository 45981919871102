import axios from "axios";
import { Skeleton } from "antd";
import Descriptions from "../../../../common/components/Descriptions/Descriptions";
import React, { useEffect, useState } from "react";
import Container from "../../../../common/components/Container/Container";
import useStoreProfile from "../../hooks/useStoreProfile";

const Account = () => {
  const { storeProfile, isLoading } = useStoreProfile();
  
  if (isLoading) {
    return (
      <Container>
        <Skeleton active />
      </Container>
    );
  }

  return (
    <Container>
      <Descriptions>
        <Descriptions.Item label={"Name"}>
          {storeProfile.name}
        </Descriptions.Item>
        <Descriptions.Item label={"Revenue"}>
          {storeProfile.revenue}
        </Descriptions.Item>
      </Descriptions>
    </Container>
  );
};

export default Account;
