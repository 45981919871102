import useSWR, { useSWRConfig } from "swr";
import axios from "axios";

const path = "/customer/order";

const useCustomerOrders = () => {
  const { data: orders, error } = useSWR(path, axios.get);
  const { mutate } = useSWRConfig();

  const addOrder = async (customer) => {
    const addedOrder = await axios.post("/order", customer);
    mutate(path);
    return addedOrder;
  };

  const cancelOrder = async (orderId) => {
    const addedOrder = await axios.delete(`/order/${orderId}`);
    mutate(path);
    return addedOrder;
  };

  return {
    orders,
    isLoading: !error && !orders,
    error: error,
    addOrder,
    cancelOrder
  };
};

export default useCustomerOrders;
