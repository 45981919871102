import useSWR, { useSWRConfig } from "swr";
import axios from "axios";

const path = "/storeprofile";

const useStoreProfile = () => {
  const { data: storeProfile, error } = useSWR(path, axios.get);
  console.log(storeProfile);
  return {
    storeProfile,
    isLoading: !error && !storeProfile,
    error: error,
  };
};

export default useStoreProfile;
