import React, { useState } from "react";
import { Layout, Menu, Typography } from "antd";
import { useHistory } from "react-router";
import { useAccessMenu } from "../../contexts/AccessMenuContext";

const { Header, Footer, Content } = Layout;
const { Title } = Typography;

const AccessLayout = ({ children }) => {
  const { selectedMenu, setSelectedMenu } = useAccessMenu();
  const history = useHistory();
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header style={{ display: "flex", alignItems: "center" }}>
        <div>
          <Title level={4} style={{ color: "white" }}>
            Grocery Express
          </Title>
        </div>
        <Menu
          style={{ marginLeft: "auto" }}
          theme="dark"
          mode="horizontal"
          selectedKeys={selectedMenu}
        >
          <Menu.Item
            key={"1"}
            onClick={() => {
              setSelectedMenu(["1"]);
              history.push("/login");
            }}
          >
            Log In
          </Menu.Item>
          <Menu.Item
            key={"2"}
            onClick={() => {
              setSelectedMenu(["2"]);
              history.push("/signup");
            }}
          >
            Register
          </Menu.Item>
        </Menu>
      </Header>
      <Content
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "white",
        }}
      >
        <div className="site-layout-content">
          <div style={{ width: "350px" }}>{children}</div>
        </div>
      </Content>
      <Footer style={{ textAlign: "center", backgroundColor: "white" }}>
        Grocery Express ©2021
      </Footer>
    </Layout>
  );
};

export default AccessLayout;
