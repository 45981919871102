import CustomerLogin from "../pages/CustomerLogin/CustomerLogin";
import CustomerSignup from "../pages/CustomerSignup/CustomerSignup";
import Login from "../pages/Login/Login";
import Signup from "../pages/Signup/Signup";
import StoreLogin from "../pages/StoreLogin/StoreLogin";
import StoreSignup from "../pages/StoreSignup/StoreSignup";

const accessRoutes = [
  { path: "/signup", component: Signup },
  { path: "/login", component: Login },
];

export default accessRoutes;
