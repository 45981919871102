import React from "react";
import { Layout, Typography } from "antd";
import styles from "./SideMenu.module.scss";
import { useAuthContext } from "../../contexts/AuthContext";
import roles from "../../constants/roles";

const { Sider } = Layout;
const { Title } = Typography;

const SideMenu = ({ menu }) => {
  const { currentUser } = useAuthContext();

  let title = null;
  switch (currentUser.role) {
    case roles.customer:
      title = "Customer";
      break;
    case roles.store:
      title = "Store";
      break;
    case roles.admin:
      title = "Admin";
      break;
  }

  return (
    <Sider breakpoint="lg" collapsible>
      <div className={styles.logo}>
        <Title level={4} style={{ color: "white" }}>
          {title}
        </Title>
      </div>
      {menu}
    </Sider>
  );
};

export default SideMenu;
