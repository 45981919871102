import React from "react";
import { Dropdown, Avatar, Menu } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useAuthContext } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import roles from "../../constants/roles";


const AccountDropdown = () => {
  const { logOut, currentUser } = useAuthContext();
  const profileLink =
    currentUser?.role === roles.customer
      ? "/customer/account"
      : "/store/account";
  const menu = (
    <Menu>
      <Link to={profileLink}>
        <Menu.Item>Profile</Menu.Item>
      </Link>
      <Menu.Item
        onClick={() => {
          logOut();
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu}>
      <Avatar
        style={{ marginRight: "16px" }}
        size="small"
        icon={<UserOutlined />}
      />
    </Dropdown>
  );
};

export default AccountDropdown;
