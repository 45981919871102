import axios from "axios";
import { Skeleton } from "antd";
import Descriptions from "../../../../common/components/Descriptions/Descriptions";
import React, { useEffect, useState } from "react";
import Container from "../../../../common/components/Container/Container";

const Account = () => {
  const [profile, setProfile] = useState(null);
  useEffect(() => {
    const getProfile = async () => {
      try {
        const profile = await axios.get("/customer");
        setProfile(profile);
      } catch (ex) {}
    };
    getProfile();
  }, []);

  if (!profile) {
    return <Skeleton active />;
  }
  console.log(profile);
  return (
    <Container>
      <Descriptions>
        <Descriptions.Item label={"Name"}>
          {profile.firstName} {profile.lastName}
        </Descriptions.Item>
        <Descriptions.Item label={"Phone Number"}>
          {profile.phoneNumber}
        </Descriptions.Item>
        <Descriptions.Item label={"Account"}>
          {profile.account}
        </Descriptions.Item>
        <Descriptions.Item label={"Rating"}>{profile.rating}</Descriptions.Item>
        <Descriptions.Item label={"Credit"}>{profile.credit}</Descriptions.Item>
      </Descriptions>
    </Container>
  );
};

export default Account;
