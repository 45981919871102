import React, { useEffect, useState } from "react";
import { Button, PageHeader, Table, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Container from "../../../../common/components/Container/Container";
import { useMenu } from "../../../../common/contexts/MenuContext";
import AddItemModal from "../../components/AddItemModal/AddItemModal";
import waitTime from "../../../../common/utils/waitTime";
import useItems from "../../hooks/useItems";
import AddPilotToDroneModal from "../../components/AddPilotToDroneModal/AddPilotToDroneModal";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Weight",
    dataIndex: "weight",
  },
  {
    title: "Price",
    dataIndex: "unitPrice",
  },
];

const Items = () => {
  const { setSelectedMenu } = useMenu();
  const { items, isLoading, addItem } = useItems();
  const [isAddItemModalVisible, setIsAddItemModalVisible] = useState(false);
  const [isAddingItem, setIsAddingItem] = useState(false);

  const handleAddItem = async (item) => {
    setIsAddingItem(true);
    await waitTime(2000);
    try {
      await addItem(item);
      message.success(`Successfully added ${item.name}!`);
    } catch (err) {
      message.error(err[0]);
    }
    setIsAddItemModalVisible(false);
    setIsAddingItem(false);
  };

  useEffect(() => {
    setSelectedMenu(["1"]);
  }, []);

  return (
    <>
      <AddItemModal
        isVisible={isAddItemModalVisible}
        onCancel={() => setIsAddItemModalVisible(false)}
        isAddingItem={isAddingItem}
        onAddItem={handleAddItem}
      />
      <Container noPadding>
        <PageHeader
          title="Items"
          className="site-page-header"
          extra={[
            <>
              <Button
                key="1"
                type="primary"
                onClick={() => setIsAddItemModalVisible(true)}
                icon={<PlusOutlined />}
              >
                Add
              </Button>
            </>,
          ]}
        />
      </Container>
      <Container>
        <Table
          rowKey={(item) => item.id}
          loading={isLoading}
          columns={columns}
          dataSource={items}
        />
      </Container>
    </>
  );
};

export default Items;
