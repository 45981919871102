import React from "react";
import { Layout } from "antd";
import SideMenu from "../../components/SideMenu/SideMenu";
import AccountDropdown from "../../components/AccountDropdown/AccountDropdown";

import "./DashboardLayout.scss";

const { Header, Content, Footer } = Layout;

const DashboardLayout = ({ menu, children }) => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <SideMenu menu={menu} />
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <AccountDropdown />
        </Header>
        <Content className="content">{children}</Content>
        <Footer style={{ textAlign: "center" }}>Grocery Express ©2021</Footer>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
