import React, { useEffect, useState } from "react";
import { Button, PageHeader, Table, message, Space, Modal } from "antd";
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import Container from "../../../../common/components/Container/Container";
import { useMenu } from "../../../../common/contexts/MenuContext";
import waitTime from "../../../../common/utils/waitTime";
import AddOrderModal from "../../components/AddOrderModal/AddOrderModal";
import useCustomerOrders from "../../hooks/useCustomerOrders";
import AddLineItemModal from "../../components/AddLineItemModal/AddLineItemModal";
import useLineItems from "../../hooks/useLineItems";
import ViewOrderModal from "../../components/ViewOrderModal/ViewOrderModal";

const { confirm } = Modal;

const Orders = () => {
  const { setSelectedMenu } = useMenu();
  const { orders, isLoading, addOrder, cancelOrder } = useCustomerOrders();
  const { addLineItem } = useLineItems();
  const [isAddOrderModalVisible, setIsAddOrderModalVisible] = useState(false);
  const [isAddingOrder, setIsAddingOrder] = useState(false);
  const [isAddingLineItemModalVisible, setIsAddingLineItemModalVisible] =
    useState(false);
  const [isViewOrderModalVisible, setIsViewOrderModalVisible] = useState(false);
  const [isAddingLineItem, setIsAddingLineItem] = useState(false);
  const [currentStore, setCurrentStore] = useState(null);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [currentViewOrder, setCurrentViewOrder] = useState(null);

  const cancelOrderConfirm = (orderId) => {
    confirm({
      title: `Confirm cancel order`,
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to cancel this order?`,
      okText: "Cancel Order",
      okType: "danger",
      cancelText: "Back",
      onOk: async () => {
        try {
          await waitTime(2000);
          await cancelOrder(orderId);
          message.success("Order successfully canceled!");
        } catch (err) {
          message.error(err);
        }
      },
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "externalId",
    },
    {
      title: "Store",
      dataIndex: "storeName",
    },
    {
      title: "Action",
      key: "action",
      render: (order) => {
        return (
          <Space>
            <a
              onClick={(e) => {
                e.preventDefault();
                setCurrentViewOrder(order);
                setIsViewOrderModalVisible(true);
                // setIsAddPilotToDroneModalVisible(true);
              }}
            >
              View Order
            </a>
            <a
              onClick={(e) => {
                e.preventDefault();
                setCurrentStore(order.storeId);
                setCurrentOrder(order.id);
                setIsAddingLineItemModalVisible(true);
                // setIsAddPilotToDroneModalVisible(true);
              }}
            >
              Add Item
            </a>
            <a
              onClick={(e) => {
                e.preventDefault();
                cancelOrderConfirm(order.id);
              }}
            >
              Cancel Item
            </a>
          </Space>
        );
      },
    },
  ];

  const handleAddOrder = async (order) => {
    setIsAddingOrder(true);
    await waitTime(2000);
    try {
      await addOrder(order);
      message.success(`Successfully added order!`);
    } catch (err) {
      message.error(err[0]);
    }
    setIsAddOrderModalVisible(false);
    setIsAddingOrder(false);
  };

  const handleAddLineItem = async (lineItem) => {
    setIsAddingLineItem(true);
    await waitTime(2000);
    try {
      await addLineItem(lineItem);
      message.success(`Successfully added Item!`);
    } catch (err) {
      message.error(err[0]);
    }
    setIsAddingLineItemModalVisible(false);
    setIsAddingLineItem(false);
    setCurrentOrder(null);
    setCurrentStore(null);
  };

  useEffect(() => {
    setSelectedMenu(["1"]);
  }, []);

  let viewOrderModal = null;
  if (isViewOrderModalVisible) {
    viewOrderModal = (
      <ViewOrderModal
        order={currentViewOrder}
        isVisible={isViewOrderModalVisible}
        onCancel={() => {
          setIsViewOrderModalVisible(false);
          setCurrentViewOrder(null);
        }}
      />
    );
  }

  let addLineItemModal = null;
  if (isAddingLineItemModalVisible) {
    addLineItemModal = (
      <AddLineItemModal
        storeId={currentStore}
        orderId={currentOrder}
        isVisible={isAddingLineItemModalVisible}
        onCancel={() => {
          setIsAddingLineItemModalVisible(false);
          setCurrentStore(null);
          setCurrentOrder(null);
        }}
        isAddingLineItem={isAddingLineItem}
        onAddLineItem={handleAddLineItem}
      />
    );
  }

  return (
    <>
      {viewOrderModal}
      {addLineItemModal}
      <AddOrderModal
        isVisible={isAddOrderModalVisible}
        onCancel={() => setIsAddOrderModalVisible(false)}
        isAddingOrder={isAddingOrder}
        onAddOrder={handleAddOrder}
      />
      <Container noPadding>
        <PageHeader
          title="Orders"
          className="site-page-header"
          extra={[
            <>
              <Button
                key="1"
                type="primary"
                onClick={() => setIsAddOrderModalVisible(true)}
                icon={<PlusOutlined />}
              >
                Add
              </Button>
            </>,
          ]}
        />
      </Container>
      <Container>
        <Table
          rowKey={(item) => item.id}
          loading={isLoading}
          columns={columns}
          dataSource={orders}
        />
      </Container>
    </>
  );
};

export default Orders;
