import React from "react";
import { Menu } from "antd";
import { PieChartOutlined, TeamOutlined } from "@ant-design/icons";
import { useMenu } from "../../../common/contexts/MenuContext";
import { useHistory } from "react-router";

const StoreMenu = () => {
  const { selectedMenu, setSelectedMenu } = useMenu();
  const history = useHistory();
  return (
    <Menu theme="dark" selectedKeys={selectedMenu} mode="vertical">
      <Menu.Item
        key="1"
        icon={<PieChartOutlined />}
        onClick={() => {
          setSelectedMenu(["1"]);
          history.push("/store/items");
        }}
      >
        Items
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<TeamOutlined />}
        onClick={() => {
          setSelectedMenu(["2"]);
          history.push("/store/drones");
        }}
      >
        Drones
      </Menu.Item>
      <Menu.Item
        key="3"
        icon={<TeamOutlined />}
        onClick={() => {
          setSelectedMenu(["3"]);
          history.push("/store/pilots");
        }}
      >
        Pilots
      </Menu.Item>
      <Menu.Item
        key="4"
        icon={<TeamOutlined />}
        onClick={() => {
          setSelectedMenu(["4"]);
          history.push("/store/orders");
        }}
      >
        Orders
      </Menu.Item>
    </Menu>
  );
};

export default StoreMenu;
