import React, { useEffect, useState } from "react";
import { PageHeader, Table } from "antd";
import Container from "../../../../common/components/Container/Container";
import { useMenu } from "../../../../common/contexts/MenuContext";
import useAdminCustomers from "../../hooks/useAdminCustomer";

const columns = [
  {
    title: "First name",
    dataIndex: "firstName",
  },
  {
    title: "Last name",
    dataIndex: "lastName",
  },
  {
    title: "Phone number",
    dataIndex: "phoneNumber",
  },
  {
    title: "Account",
    dataIndex: "account",
  },
  {
    title: "Credit",
    dataIndex: "credit",
  },
  {
    title: "Rating",
    dataIndex: "rating",
  },
];

const Customers = () => {
  const { setSelectedMenu } = useMenu();
  const { customers, isLoading } = useAdminCustomers();
  useEffect(() => {
    setSelectedMenu(["2"]);
  }, []);
  console.log(customers);
  return (
    <>
      <Container noPadding>
        <PageHeader title="Customers" className="site-page-header" />
      </Container>
      <Container>
        <Table
          rowKey={(item) => item.id}
          loading={isLoading}
          columns={columns}
          dataSource={customers}
        />
      </Container>
    </>
  );
};

export default Customers;
