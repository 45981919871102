import React, { useEffect, useState } from "react";
import { Button, PageHeader, Table, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Container from "../../../../common/components/Container/Container";
import { useMenu } from "../../../../common/contexts/MenuContext";
import waitTime from "../../../../common/utils/waitTime";
import usePilots from "../../hooks/usePilots";
import AddPilotModal from "../../components/AddPilotModal/AddPilotModal";
import AddPilotToDroneModal from "../../components/AddPilotToDroneModal/AddPilotToDroneModal";

const columns = [
  {
    title: "First Name",
    dataIndex: "firstName",
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
  },
  {
    title: "Phone Number",
    dataIndex: "phoneNumber",
  },
  {
    title: "Account",
    dataIndex: "account",
  },
  {
    title: "Tax ID",
    dataIndex: "taxId",
  },
  {
    title: "License ID",
    dataIndex: "licenseId",
  },
  {
    title: "Experience",
    dataIndex: "experience",
  },
];

const Pilots = () => {
  const { setSelectedMenu } = useMenu();
  const { pilots, isLoading, addPilot } = usePilots();
  const [isAddPilotModalVisible, setIsAddPilotModalVisible] = useState(false);
  const [isAddingPilot, setIsAddingPilot] = useState(false);

  const handleAddPilot = async (pilot) => {
    setIsAddingPilot(true);
    await waitTime(2000);
    try {
      await addPilot(pilot);
      message.success(`Successfully added Pilot!`);
    } catch (err) {
      console.log(err);
      message.error(err[0]);
    }
    setIsAddPilotModalVisible(false);
    setIsAddingPilot(false);
  };

  useEffect(() => {
    setSelectedMenu(["3"]);
  }, []);

  return (
    <>
      <AddPilotModal
        isVisible={isAddPilotModalVisible}
        onCancel={() => setIsAddPilotModalVisible(false)}
        isAddingPilot={isAddingPilot}
        onAddPilot={handleAddPilot}
      />
      <Container noPadding>
        <PageHeader
          title="Pilots"
          className="site-page-header"
          extra={[
            <>
              <Button
                key="1"
                type="primary"
                onClick={() => setIsAddPilotModalVisible(true)}
                icon={<PlusOutlined />}
              >
                Add
              </Button>
            </>,
          ]}
        />
      </Container>
      <Container>
        <Table
          rowKey={(item) => item.id}
          loading={isLoading}
          columns={columns}
          dataSource={pilots}
        />
      </Container>
    </>
  );
};

export default Pilots;
