const environment = {
  API_URL: "https://grocery-express-api.onrender.com/api",
  firebase: {
    API_KEY: "AIzaSyBJUlhbr26yCHEFwpvJMPjwRtxKk_tOpSY",
    AUTH_DOMAIN: "grocery-express-b1dbb.firebaseapp.com",
    PROJECT_ID: "grocery-express-b1dbb",
    STORAGE_BUCKET: "grocery-express-b1dbb.appspot.com",
    MESSAGING_SENDER_ID: "338778410876",
    APP_ID: "1:338778410876:web:4d934a1a6161caed419ef9",
  },
};

export default environment;