import React, { useState, useEffect } from "react";
import { Form, Input, Button } from "antd";
import {
  LockOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  MailOutlined,
  DollarCircleOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { useAuthContext } from "../../../../common/contexts/AuthContext";
import waitTime from "../../../../common/utils/waitTime";
import { passwordStrengthValidator } from "../../../../common/validators/validators";

const StoreSignup = () => {
  const { signUp, login } = useAuthContext();
  const [isSigningUp, setIsSigningUp] = useState(false);
  const history = useHistory();

  const handleSignup = async (values) => {
    setIsSigningUp(true);
    await waitTime(2000);
    const { email, storeName, password, revenue } = values;
    const store = {
      name: storeName,
      email,
      password,
      revenue,
    };
    try {
      await signUp(store);
      await login(email, password);
      history.push("/store/items");
    } catch (err) {
      console.log(err);
    }
    setIsSigningUp(false);
  };

  return (
    <Form name="normal_login" onFinish={handleSignup}>
      <Form.Item
        name="storeName"
        rules={[
          {
            required: true,
            message: "Please enter a store name",
          },
        ]}
      >
        <Input
          prefix={<ShopOutlined className="site-form-item-icon" />}
          placeholder="Store Name"
        />
      </Form.Item>
      <Form.Item
        name="revenue"
        rules={[
          {
            required: true,
            message: "Please enter a revnue",
          },
          () => ({
            validator(_, revenue) {
              const regEx = /^[\d]*$/;
              if (!regEx.test(revenue)) {
                return Promise.reject(
                  new Error("Revenue must be an whole number!")
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input
          prefix={<DollarCircleOutlined className="site-form-item-icon" />}
          placeholder="Revenue"
        />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
      >
        <Input
          prefix={<MailOutlined className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your Password!",
          },
          passwordStrengthValidator(),
        ]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>
      <Form.Item
        name="confirm"
        dependencies={["password"]}
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }

              return Promise.reject(
                new Error("The two passwords that you entered do not match!")
              );
            },
          }),
        ]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Confirm Password"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
          loading={isSigningUp}
        >
          Sign up
        </Button>
        Have an account already? <Link to="/store/login">Login</Link>
      </Form.Item>
    </Form>
  );
};

export default StoreSignup;
