import useSWR, { useSWRConfig } from "swr";
import axios from "axios";

const path = "/item";

const useItems = () => {
  const { data: items, error } = useSWR(path, axios.get);
  const { mutate } = useSWRConfig();

  const addItem = async (item) => {
    const addedItem = await axios.post(path, item);
    mutate(path);
    return addedItem;
  };

  return {
    items,
    isLoading: !error && !items,
    error: error,
    addItem,
  };
};

export default useItems;
