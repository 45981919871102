import axios from "axios";
import environment from "./environment";
import { getAuth } from "firebase/auth";

const auth = getAuth();

const { API_URL } = environment;

axios.defaults.baseURL = API_URL;

axios.interceptors.request.use(async (request) => {
  const user = auth.currentUser;
  if (user) {
    const token = await user.getIdToken();
    console.log(token);
    request.headers["Authorization"] = `Bearer ${token}`;
  }
  return request;
});

axios.interceptors.response.use(
  async (response) => {
    return response.data;
  },
  (err) => {
    const status = err.response?.status;
    const data = err.response?.data;
    const errors = data?.errors;
    console.log('hello')
    if (errors) {
      switch (status) {
        case 400: {
          let errors = [];
          for (var key in data.errors) {
            errors = [...errors, ...data.errors[key]];
          }
          return Promise.reject(errors);
        }
      }
    }
    return Promise.reject(err);
  }
);
