import React, { useState, useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import {
  LockOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  MailOutlined,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { useAuthContext } from "../../../../common/contexts/AuthContext";
import waitTime from "../../../../common/utils/waitTime";
import { useAccessMenu } from "../../../../common/contexts/AccessMenuContext";

const Login = () => {
  const { login } = useAuthContext();
  const [isLogginIn, setIsLogginIn] = useState(false);
  const history = useHistory();
  const { setSelectedMenu } = useAccessMenu();

  useEffect(() => {
    setSelectedMenu(["1"]);
  }, []);

  const handleLogin = async (values) => {
    setIsLogginIn(true);
    await waitTime(2000);
    const { email, password } = values;
    try {
      await login(email, password);
      history.push("/");
    } catch (err) {
      message.error(err.message);
    }
    setIsLogginIn(false);
  };

  return (
    <Form name="normal_login" onFinish={handleLogin}>
      <Form.Item
        name="email"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
      >
        <Input
          prefix={<MailOutlined className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your Password!",
          },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
          loading={isLogginIn}
        >
          Log in
        </Button>
        Or <Link to="/signup">Register Now!</Link>
      </Form.Item>
    </Form>
  );
};

export default Login;
