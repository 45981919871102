import React, { useEffect } from "react";
import { Modal, Form, Input, Select } from "antd";
import { wholeNumberGreaterThanZeroValidator } from "../../../../common/validators/validators";
import useStores from "../../../../common/hooks/useStores";

const { Option } = Select;

const AddOrderModal = ({ isVisible, onAddOrder, onCancel, isAddingOrder }) => {
  const { stores } = useStores();
  console.log(stores);
  const [form] = Form.useForm();

  useEffect(() => {
    if (stores && stores.length > 0) {
      form.setFieldsValue({ store: stores[0].id });
    }
  }, [stores]);

  return (
    <Modal
      visible={isVisible}
      title="Add Order"
      okText="Add"
      cancelText="Cancel"
      onCancel={onCancel}
      confirmLoading={isAddingOrder}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onAddOrder({
              storeId: values.store,
              externalId: values.externalId,
            });
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Form.Item label="Store" name="store">
          <Select
            showSearch
            defaultActiveFirstOption={false}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {stores?.map((store) => (
              <Option value={store.id}>{store.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="externalId"
          label="Name"
          rules={[
            {
              required: true,
              message: "Name is required!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddOrderModal;
