import React, { createContext, useContext, useState } from "react";

const AccessMenuContext = createContext(["1"]);

const AccessMenuProvider = ({ children }) => {
  const [selectedMenu, setSelectedMenu] = useState(["1"]);
  return (
    <AccessMenuContext.Provider value={{ selectedMenu, setSelectedMenu }}>
      {children}
    </AccessMenuContext.Provider>
  );
};

const useAccessMenu = () => {
  const { selectedMenu, setSelectedMenu } = useContext(AccessMenuContext);
  return { selectedMenu, setSelectedMenu };
};

export { AccessMenuProvider, useAccessMenu };
