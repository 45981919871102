import useSWR, { useSWRConfig } from "swr";
import axios from "axios";

const path = "/customer";

const useCustomer = () => {
  //   const { data: pilots, error } = useSWR(path, axios.get);
    // const { mutate } = useSWRConfig();

  const addCustomer = async (customer) => {
    const addedCustomer = await axios.post(path, customer);
    // mutate(path);
    return addedCustomer;
  };

  return {
    // pilots,
    // isLoading: !error && !pilots,
    // error: error,
    addCustomer,
  };
};

export default useCustomer;
