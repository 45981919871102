import { useSWRConfig } from "swr";
import axios from "axios";

const path = "/customer/order";

const useCustomerOrders = () => {
  const { mutate } = useSWRConfig();

  const addLineItem = async (lineItem) => {
    const addedLineItem = await axios.post("/lineitem", lineItem);
    mutate("customer/order");
    return addedLineItem;
  };

  return {
    addLineItem,
  };
};

export default useCustomerOrders;
