import React from "react";
import { Button, Modal } from "antd";
import Descriptions from "../../../../common/components/Descriptions/Descriptions";

const ViewOrderModal = ({ order, isVisible, onCancel }) => {
  return (
    <Modal
      visible={isVisible}
      title="View Order"
      footer={[
        <Button key="back" type="primary" onClick={onCancel}>
          Return
        </Button>,
      ]}
    >
      <Descriptions>
        <Descriptions.Item label={"Items"}>
          {order?.lineItems.map((lineItem) => {
            return (
              <>
                <Descriptions.Item label={"Name"}>
                  {lineItem.name}
                </Descriptions.Item>
                <Descriptions.Item label={"Quantity"}>
                  {lineItem.quantity}
                </Descriptions.Item>
                <Descriptions.Item label={"Price"}>
                  {lineItem.unitPrice}
                </Descriptions.Item>
              </>
            );
          })}
        </Descriptions.Item>
        <Descriptions.Item label={"Total cost"}>
          {order.totalCost}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export default ViewOrderModal;
