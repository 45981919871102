import useSWR from "swr";
import axios from "axios";

const useItemsForCustomer = (storeId) => {
  const { data: items, error } = useSWR(`item/${storeId}`, axios.get);

  return {
    items,
    isLoading: !error && !items,
    error: error,
  };
};

export default useItemsForCustomer;
