import React from "react";
import { Redirect, Route } from "react-router";
import { useAuthContext } from "../contexts/AuthContext";
import roles from "../constants/roles";

const redirectRoutes = {
  [roles.store]: "/store/items",
  [roles.customer]: "/customer/orders",
  [roles.admin]: "/admin/stores",
};

const AuthRedirectRoute = ({ props }) => {
  const { currentUser } = useAuthContext();
  const user = currentUser?.user;
  const role = currentUser?.role;
  console.log(role)
  let redirectRoute = redirectRoutes[role];
  return (
    <Route
      {...props}
      render={() =>
        user && role ? (
          <Redirect to={redirectRoute} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default AuthRedirectRoute;
