import React from "react";
import { Modal, Form, Input } from "antd";
import { wholeNumberGreaterThanZeroValidator } from "../../../../common/validators/validators";

const AddDroneModal = ({ isVisible, onAddDrone, onCancel, isAddingDrone }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={isVisible}
      title="Add Drone"
      okText="Add"
      cancelText="Cancel"
      onCancel={onCancel}
      confirmLoading={isAddingDrone}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            const {
              id: externalId,
              numTripsBeforeMaitenance,
              totalWeightCapacity,
            } = values;
            onAddDrone({
              externalId,
              numTripsBeforeMaitenance,
              totalWeightCapacity,
            });
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Form.Item
          name="id"
          label="ID"
          rules={[
            {
              required: true,
              message: "ID is required!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="totalWeightCapacity"
          label="Weight Capacity"
          rules={[
            {
              required: true,
              message: "Weight is required!",
            },
            wholeNumberGreaterThanZeroValidator(
              "Weight needs to be a whole number and greater than 0"
            ),
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="numTripsBeforeMaitenance"
          label="# of Trips before Maitenance"
          rules={[
            {
              required: true,
              message: "Trips is required",
            },
            wholeNumberGreaterThanZeroValidator(
              "Trips needs to be a whole number and greater than 0"
            ),
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddDroneModal;
