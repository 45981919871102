import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import {
  LockOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  MailOutlined,
  StarOutlined,
  UserOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { useAuthContext } from "../../../../common/contexts/AuthContext";
import waitTime from "../../../../common/utils/waitTime";
import {
  nonNegativeWholeNumberValidator,
  phoneNumberValidator,
  passwordStrengthValidator,
} from "../../../../common/validators/validators";
import useCustomer from "../../hooks/useCustomer/useCustomer";

const CustomerSignup = () => {
  const { login } = useAuthContext();
  const { addCustomer } = useCustomer();
  const [isSigningUp, setIsSigningUp] = useState(false);
  const history = useHistory();

  const handleSignup = async (values) => {
    setIsSigningUp(true);
    await waitTime(2000);
    try {
      const customer = {
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        rating: values.rating,
        account: values.externalId,
        credit: values.credit,
      };
      await addCustomer(customer);
      await login(customer.email, customer.password);
      history.push("/customer/orders");
    } catch (err) {
      message.error(err)
      console.log(err);
    }
    setIsSigningUp(false);
  };

  return (
    <Form name="normal_login" onFinish={handleSignup}>
      <Form.Item
        name="email"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
      >
        <Input
          prefix={<MailOutlined className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your Password!",
          },
          passwordStrengthValidator(),
        ]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>
      <Form.Item
        name="confirm"
        dependencies={["password"]}
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }

              return Promise.reject(
                new Error("The two passwords that you entered do not match!")
              );
            },
          }),
        ]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Confirm Password"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>
      <Form.Item
        name="externalId"
        rules={[
          {
            required: true,
            message: "Account is required!",
          },
        ]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeHolder={"Account"}
        />
      </Form.Item>
      <Form.Item
        name="firstName"
        rules={[
          {
            required: true,
            message: "First name is required!",
          },
        ]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeHolder={"First Name"}
        />
      </Form.Item>
      <Form.Item
        name="lastName"
        rules={[
          {
            required: true,
            message: "Last name is required!",
          },
        ]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Last name"
        />
      </Form.Item>
      <Form.Item
        name="phoneNumber"
        rules={[
          {
            required: true,
            message: "Phone number is required!",
          },
          phoneNumberValidator(
            "Phone number has to be the format XXX-XXX-XXXX"
          ),
        ]}
      >
        <Input
          prefix={<PhoneOutlined className="site-form-item-icon" />}
          placeholder="Phone number"
        />
      </Form.Item>
      <Form.Item
        name="rating"
        rules={[
          {
            required: true,
            message: "Rating is required!",
          },
          nonNegativeWholeNumberValidator(
            "Rating has to be a whole number greater than or equal to 0"
          ),
        ]}
      >
        <Input
          prefix={<StarOutlined className="site-form-item-icon" />}
          placeholder="Rating"
        />
      </Form.Item>
      <Form.Item
        name="credit"
        rules={[
          {
            required: true,
            message: "Credit is required!",
          },
          nonNegativeWholeNumberValidator(
            "Credit has to be a whole number greater than or equal to 0"
          ),
        ]}
      >
        <Input
          prefix={<StarOutlined className="site-form-item-icon" />}
          placeholder="Credit"
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
          loading={isSigningUp}
        >
          Sign up
        </Button>
        Have an account already? <Link to="/store/login">Login</Link>
      </Form.Item>
    </Form>
  );
};

export default CustomerSignup;
