import React from "react";
import { Switch } from "react-router";
import accessRoutes from "./features/access/routes/accessRoutes";
import AccessRoute from "./common/routes/AccessRoute";
import AuthRedirectRoute from "./common/routes/AuthRedirectRoute";
import storeRoutes from "./features/store/routes/storeRoutes";
import AuthRoute from "./common/routes/AuthRoute";
import StoreMenu from "./features/store/menu/StoreMenu";

import "./App.css";
import customerRoutes from "./features/customer/routes/customerRoutes";
import CustomerMenu from "./features/customer/menu/CustomerMenu";
import AdminMenu from "./features/admin/menu/AdminMenu";
import adminRoutes from "./features/admin/routes/adminRoutes";

const App = () => {
  return (
    <Switch>
      {accessRoutes.map((accessRoute) => (
        <AccessRoute
          key={accessRoute.path}
          exact
          path={accessRoute.path}
          component={accessRoute.component}
        />
      ))}
      {storeRoutes.map((storeRoute) => (
        <AuthRoute
          key={storeRoute.path}
          exact
          path={storeRoute.path}
          component={storeRoute.component}
          menu={<StoreMenu />}
        />
      ))}
      {customerRoutes.map((customerRoute) => (
        <AuthRoute
          key={customerRoute.path}
          exact
          path={customerRoute.path}
          component={customerRoute.component}
          menu={<CustomerMenu />}
        />
      ))}
      {adminRoutes.map((adminRoute) => (
        <AuthRoute
          key={adminRoute.path}
          exact
          path={adminRoute.path}
          component={adminRoute.component}
          menu={<AdminMenu />}
        />
      ))}
      <AuthRedirectRoute exact path="/" />
    </Switch>
  );
};

export default App;
