import React, { useEffect, useState } from "react";
import { Button, PageHeader, Table, message, Space, Modal } from "antd";
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import Container from "../../../../common/components/Container/Container";
import { useMenu } from "../../../../common/contexts/MenuContext";
import waitTime from "../../../../common/utils/waitTime";
import useDrones from "../../hooks/useDrones";
import AddDroneModal from "../../components/AddDroneModal/AddDroneModal";
import AddPilotToDroneModal from "../../components/AddPilotToDroneModal/AddPilotToDroneModal";

const { confirm } = Modal;

const Drones = () => {
  const { setSelectedMenu } = useMenu();
  const { drones, isLoading, addDrone, updateDrone } = useDrones();
  const [isAddDroneModalVisible, setIsAddDroneModalVisible] = useState(false);
  const [isAddingDrone, setIsAddingDrone] = useState(false);
  const [isAddPilotToDroneModalVisible, setIsAddPilotToDroneModalVisible] =
    useState(false);
  const [isAddingPilotToDrone, setIsAddingPilotToDrone] = useState(false);
  const [currentDrone, setCurrentDrone] = useState(null);

  const confirmRemovePilot = (drone) => {
    console.log(drone);
    confirm({
      title: `Remove pilot`,
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to remove the pilot?`,
      okText: "Remove",
      okType: "danger",
      cancelText: "Back",
      onOk: async () => {
        try {
          await waitTime(2000);
          const updatedDrone = {
            id: drone.id,
            numTripsBeforeMaitenance: drone.numTripsBeforeMaitenance,
            remainingWeightCapacity: drone.remainingWeightCapacity,
            pilotId: null,
          };
          await updateDrone(updatedDrone);
          message.success("Pilot successfully removed!");
        } catch (err) {
          message.error(err);
        }
      },
    });
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "externalId",
    },
    {
      title: "Remaining Weight Capacity",
      dataIndex: "remainingWeightCapacity",
    },
    {
      title: "Total Weight Capacity",
      dataIndex: "totalWeightCapacity",
    },
    {
      title: "Num Orders",
      render: (record) => {
        console.log(record);
        return record.orders.length;
      },
    },
    {
      title: "# Trips Before Maitenance",
      dataIndex: "numTripsBeforeMaitenance",
    },
    {
      title: "Current pilot",
      render: (drone) => {
        const pilot = drone?.pilot;
        if (pilot) {
          const { firstName, lastName, account } = pilot;
          return `${firstName} ${lastName} (Account: ${account})`;
        }
        return "None";
      },
    },
    {
      title: "Action",
      key: "action",
      render: (drone) => {
        return (
          <Space>
            <a
              onClick={(e) => {
                e.preventDefault();
                setCurrentDrone(drone);
                setIsAddPilotToDroneModalVisible(true);
              }}
            >
              Add Pilot
            </a>
            <a
              onClick={(e) => {
                e.preventDefault();
                confirmRemovePilot(drone);
              }}
            >
              Remove Pilot
            </a>
          </Space>
        );
      },
    },
  ];

  const handleAddPilotToDrone = async (pilotId) => {
    setIsAddingPilotToDrone(true);
    await waitTime(2000);
    try {
      if (currentDrone) {
        const { numTripsBeforeMaitenance, remainingWeightCapacity } =
          currentDrone;
        const updatedDrone = {
          id: currentDrone.id,
          numTripsBeforeMaitenance,
          remainingWeightCapacity,
          pilotId,
        };
        await updateDrone(updatedDrone);
      } else {
        message.error("Something went wrong! Please try again");
      }
    } catch (err) {
      console.log(err);
      message.error(err[0]);
    }
    setCurrentDrone(null);
    setIsAddPilotToDroneModalVisible(false);
    setIsAddingPilotToDrone(false);
  };

  const handleAddDrone = async (drone) => {
    setIsAddingDrone(true);
    await waitTime(2000);
    try {
      await addDrone(drone);
      message.success(`Successfully added Drone!`);
    } catch (err) {
      console.log(err);
      message.error(err[0]);
    }
    setIsAddDroneModalVisible(false);
    setIsAddingDrone(false);
  };

  useEffect(() => {
    setSelectedMenu(["2"]);
  }, []);

  return (
    <>
      <AddPilotToDroneModal
        isAddingPilotToDrone={isAddingPilotToDrone}
        onAddPilotToDrone={handleAddPilotToDrone}
        isVisible={isAddPilotToDroneModalVisible}
        onCancel={() => setIsAddPilotToDroneModalVisible(false)}
      />
      <AddDroneModal
        isVisible={isAddDroneModalVisible}
        onCancel={() => {
          setCurrentDrone(null);
          setIsAddDroneModalVisible(false);
        }}
        isAddingDrone={isAddingDrone}
        onAddDrone={handleAddDrone}
      />
      <Container noPadding>
        <PageHeader
          title="Drones"
          className="site-page-header"
          extra={[
            <>
              <Button
                key="1"
                type="primary"
                onClick={() => setIsAddDroneModalVisible(true)}
                icon={<PlusOutlined />}
              >
                Add
              </Button>
            </>,
          ]}
        />
      </Container>
      <Container>
        <Table
          rowKey={(item) => item.id}
          loading={isLoading}
          columns={columns}
          dataSource={drones}
        />
      </Container>
    </>
  );
};

export default Drones;
