import React from "react";
import { Route } from "react-router";
import AccessLayout from "../layouts/AccessLayout/AccessLayout";

const AccessRoute = ({ component: Component, ...props }) => {
  return (
    <Route
      {...props}
      render={(props) => {
        return (
          <AccessLayout>
            <Component {...props} />
          </AccessLayout>
        );
      }}
    />
  );
};

export default AccessRoute;
