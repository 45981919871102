import React, { useEffect } from "react";
import { Modal, Form, Input, Select } from "antd";
import { wholeNumberGreaterThanZeroValidator } from "../../../../common/validators/validators";
import useItemsForCustomer from "../../hooks/useItemsForCustomer";

const { Option } = Select;

const AddLineItemModal = ({
  storeId,
  orderId,
  isVisible,
  onAddLineItem,
  onCancel,
  isAddingLineItem,
}) => {
  const { items } = useItemsForCustomer(storeId);
  console.log(items);
  const [form] = Form.useForm();

  useEffect(() => {
    if (items && items.length > 0) {
      form.setFieldsValue({ item: items[0].id });
    }
  }, [items]);

  return (
    <Modal
      visible={isVisible}
      title="Add Order"
      okText="Add"
      cancelText="Cancel"
      onCancel={onCancel}
      confirmLoading={isAddingLineItem}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onAddLineItem({
              orderId,
              storeId,
              itemId: values.item,
              quantity: values.quantity,
            });
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Form.Item label="Item" name="item">
          <Select
            showSearch
            defaultActiveFirstOption={false}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {items?.map((item) => (
              <Option value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="quantity"
          label="Quantity"
          rules={[
            {
              required: true,
              message: "Quantity is required!",
            },
            wholeNumberGreaterThanZeroValidator(
              "Quantity needs to be a whole number and greater than 0"
            ),
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddLineItemModal;
