import React from "react";

const Container = ({ noPadding, children }) => {
  return (
    <div
      style={{ background: "white", margin: 16, padding: noPadding ? 0 : 24 }}
    >
      {children}
    </div>
  );
};

export default Container;
