import React, { useEffect } from "react";
import { Modal, Form, Select } from "antd";
import usePilots from "../../hooks/usePilots";

const { Option } = Select;

const AddPilotToDroneModal = ({
  isVisible,
  onAddPilotToDrone,
  onCancel,
  isAddingPilotToDrone,
}) => {
  const [form] = Form.useForm();
  const { pilots } = usePilots();

  useEffect(() => {
    if (pilots && pilots.length > 0) {
      form.setFieldsValue({ pilot: pilots[0].id });
    }
  }, [pilots]);

  return (
    <Modal
      visible={isVisible}
      title="Add Pilot To Drone"
      okText="Add"
      cancelText="Cancel"
      onCancel={onCancel}
      confirmLoading={isAddingPilotToDrone}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            const { pilot } = values;
            onAddPilotToDrone(pilot);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Form.Item label="Select Pilot" name="pilot">
          <Select>
            {pilots?.map((pilot) => (
              <Option
                value={pilot.id}
              >{`${pilot.firstName} ${pilot.lastName} (Account: ${pilot.account})`}</Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddPilotToDroneModal;
