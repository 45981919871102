import React, { useState, useEffect } from "react";
import { Table, PageHeader, Modal, message, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import Container from "../../../../common/components/Container/Container";
import useStoreOrders from "../../hooks/useStoreOrders";
import waitTime from "../../../../common/utils/waitTime";
import ViewOrderModal from "../../components/ViewOrderModal/ViewOrderModal";
import { useMenu } from "../../../../common/contexts/MenuContext";

const { confirm } = Modal;

const Orders = () => {
  const { setSelectedMenu } = useMenu();
  const [isViewOrderModalVisible, setIsViewOrderModalVisible] = useState(false);
  const [currentViewOrder, setCurrentViewOrder] = useState(null);
  const { orders, isLoading, completeOrder } = useStoreOrders();

  useEffect(() => {
    setSelectedMenu(["4"]);
  }, []);

  const completeOrderConfirm = (orderId) => {
    confirm({
      title: `Confirm complete order`,
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to complete this order?`,
      okText: "Complete",
      okType: "success",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await waitTime(2000);
          await completeOrder(orderId);
          message.success("Order successfully completed!");
        } catch (err) {
          message.error(err);
        }
      },
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "externalId",
    },
    {
      title: "Action",
      key: "action",
      render: (order) => {
        return (
          <Space>
            <a
              onClick={(e) => {
                e.preventDefault();
                setCurrentViewOrder(order);
                setIsViewOrderModalVisible(true);
              }}
            >
              View Order
            </a>
            <a
              onClick={(e) => {
                e.preventDefault();
                completeOrderConfirm(order.id);
              }}
            >
              Complete Order
            </a>
          </Space>
        );
      },
    },
  ];

  let viewOrderModal = null;
  if (isViewOrderModalVisible) {
    viewOrderModal = (
      <ViewOrderModal
        order={currentViewOrder}
        isVisible={isViewOrderModalVisible}
        onCancel={() => {
          setIsViewOrderModalVisible(false);
          setCurrentViewOrder(null);
        }}
      />
    );
  }

  return (
    <>
      {viewOrderModal}
      <Container noPadding>
        <PageHeader title="Orders" className="site-page-header" />
      </Container>
      <Container>
        <Table
          rowKey={(order) => order.id}
          loading={isLoading}
          columns={columns}
          dataSource={orders}
        />
      </Container>
    </>
  );
};

export default Orders;
