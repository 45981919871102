const wholeNumberGreaterThanZeroValidator = (errorMessage) => {
  return () => ({
    validator(_, value) {
      const regEx = /^([1-9][0-9]+|[1-9])$/;
      if (!regEx.test(value)) {
        return Promise.reject(new Error(errorMessage));
      }
      return Promise.resolve();
    },
  });
};

const nonNegativeWholeNumberValidator = (errorMessage) => {
  return () => ({
    validator(_, value) {
      const regEx = /^[0-9]\d*$/;
      if (!regEx.test(value)) {
        return Promise.reject(new Error(errorMessage));
      }
      return Promise.resolve();
    },
  });
};

const phoneNumberValidator = (errorMessage) => {
  return () => ({
    validator(_, value) {
      const regEx = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
      if (!regEx.test(value)) {
        return Promise.reject(new Error(errorMessage));
      }
      return Promise.resolve();
    },
  });
};

const passwordStrengthValidator = () => {
  return () => ({
    validator(_, value) {
      const regEx = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
      if (!regEx.test(value)) {
        return Promise.reject(
          new Error(
            "Your password has to be atleast 6 characters with numbers and letters mixed in"
          )
        );
      }
      return Promise.resolve();
    },
  });
};

export {
  wholeNumberGreaterThanZeroValidator,
  phoneNumberValidator,
  nonNegativeWholeNumberValidator,
  passwordStrengthValidator,
};
