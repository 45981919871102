import React from "react";
import { Modal, Form, Input } from "antd";
import { wholeNumberGreaterThanZeroValidator } from "../../../../common/validators/validators";

const AddItemModal = ({ isVisible, onAddItem, onCancel, isAddingItem }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={isVisible}
      title="Add Item"
      okText="Add"
      cancelText="Cancel"
      onCancel={onCancel}
      confirmLoading={isAddingItem}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            const { name, weight, unitPrice } = values;
            onAddItem({ name, weight, unitPrice });
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Name is required!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="weight"
          label="Weight"
          rules={[
            {
              required: true,
              message: "Weight is required!",
            },
            wholeNumberGreaterThanZeroValidator(
              "Weight needs to be a whole number and greater than 0"
            ),
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="unitPrice"
          label="Price"
          rules={[
            {
              required: true,
              message: "Price is required!",
            },
            wholeNumberGreaterThanZeroValidator(
              "Price needs to be a whole number and greater than 0"
            ),
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddItemModal;
