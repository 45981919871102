import useSWR, { useSWRConfig } from "swr";
import axios from "axios";

const path = "/store";

const useStores = () => {
  const { data: stores, error } = useSWR(path, axios.get);

  return {
    stores,
    isLoading: !error && !stores,
    error: error,
  };
};

export default useStores;
