import React, { useEffect } from "react";
import { Tabs } from "antd";
import CustomerSignup from "../CustomerSignup/CustomerSignup";
import StoreSignup from "../StoreSignup/StoreSignup";
import { useAccessMenu } from "../../../../common/contexts/AccessMenuContext";

const { TabPane } = Tabs;

const Signup = () => {
  const { selectedMenu, setSelectedMenu } = useAccessMenu();
  console.log(selectedMenu);
  useEffect(() => {
    setSelectedMenu(["2"]);
  }, []);

  return (
    <Tabs defaultActiveKey="1" onChange={() => {}}>
      <TabPane tab="Customer" key="1">
        <CustomerSignup />
      </TabPane>
      <TabPane tab="Store" key="2">
        <StoreSignup />
      </TabPane>
    </Tabs>
  );
};

export default Signup;
