import useSWR, { useSWRConfig } from "swr";
import axios from "axios";

const path = "/store/order";

const useStoreOrders = () => {
  const { data: orders, error } = useSWR(path, axios.get);
  const { mutate } = useSWRConfig();

  const completeOrder = async (orderId) => {
    const completedOrder = await axios.post('/store/order/complete', { orderId });
    mutate(path);
    mutate("/storeprofile");
    mutate("/store/order");
    return completedOrder;
  };

  return {
    orders,
    isLoading: !error && !orders,
    error: error,
    completeOrder,
  };
};

export default useStoreOrders;
