import useSWR from "swr";
import axios from "axios";

const path = "/admin/store";

const useAdminStores = () => {
  const { data: stores, error } = useSWR(path, axios.get);

  return {
    stores,
    isLoading: !error && !stores,
    error: error,
  };
};

export default useAdminStores;
